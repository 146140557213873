
.booth-ad-content {
    /*margin: 0 20px;*/
    .header {
        margin: 0 20px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #343434;
    }
    .area-header {
        display: flex;
        align-items: flex-end;
        margin: 20px;
        .focus-text {
            font-size: 14px;
            color: #343434;
            margin-left: 13px;
            font-weight: 400;
            .focus-city {
                font-size: 14px;
                color: #FF0000;
            }
        }
    }
    .chart-tab {
        margin: 20px 20px 15px;
        display: flex;
        flex-direction: column;
        .data-content {
            height: 330px;
            margin-top: 30px;
        }
        // 多选框的颜色
        ::v-deep .el-checkbox-group {
            .el-checkbox {
                .el-checkbox__input {
                    .el-checkbox__inner {
                        &:hover {
                            border-color: #FD4446;
                        }
                    }
                    &.is-focus {
                        .el-checkbox__inner {
                            border-color: #FD4446;
                        }
                    }
                }
                &.is-checked {
                    .el-checkbox__input {
                        &.is-checked {
                            .el-checkbox__inner {
                                background-color: #FD4446;
                                border-color: #FD4446;
                            }
                        }
                        &:hover {
                            border-color: #FD4446;
                        }
                    }
                    .el-checkbox__label {
                        color: #343434;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .cut-off{
        width: 100%;
        height: 20px;
        background: #F7F9FB;
    }
    .area-table {
        margin: 0 20px;
        ::v-deep .el-table {
            .el-table__body-wrapper {
                .el-table__row {
                    .ranking {
                        width: 24px;
                        height: 24px;
                        line-height: 24px;
                        text-align: center;
                        font-weight: 500;
                        background-size: cover;
                        display: inline-block;
                    }
                    &:first-child {
                        .ranking {
                            background: url("../../../../../assets/images/dataCenter/one.png") no-repeat center;
                        }
                    }
                    &:nth-of-type(2) {
                        .ranking {
                            background: url("../../../../../assets/images/dataCenter/two.png") no-repeat center;
                        }
                    }
                    &:nth-of-type(3) {
                        .ranking {
                            background: url("../../../../../assets/images/dataCenter/three.png") no-repeat center;
                        }
                    }
                }
            }
        }
    }
}
.area-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .percentage {
        width: 40px;
        text-align: right;
    }
    ::v-deep .el-progress {
        width: 1%;
        flex: 1;
        padding: 0 10px;
    }
}
